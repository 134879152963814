import { useCssVars as _useCssVars, defineComponent as _defineComponent } from 'vue';
import { unref as _unref, withKeys as _withKeys, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "base-datepicker__month-year" };
const _hoisted_2 = { class: "base-datepicker__month-year-wrapper" };
const _hoisted_3 = { class: "base-datepicker__time" };
const _hoisted_4 = { class: "base-datepicker__actions" };
import { DATEPICKER_MODE, SELECT_MODE } from "@/components/@base/@types";
import { THEME } from "@/components/@types";
import { BaseSelect, BaseInput, BaseIcon } from "@/components/@base";
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { computed, ref, toRef, watch, reactive } from 'vue';
const __default__ = { name: 'BaseDatepicker' };
export default /*@__PURE__*/ _defineComponent({
    ...__default__,
    props: {
        modelValue: { default: null },
        mode: { default: DATEPICKER_MODE.date },
        theme: { default: THEME.light },
        width: { default: '' },
        disabled: { type: Boolean, default: false }
    },
    emits: ["update:modelValue"],
    setup(__props, { emit: __emit }) {
        _useCssVars(_ctx => ({
            "17c56cff": (data.width)
        }));
        const props = __props;
        const dp = ref(undefined);
        const modelValue = toRef(props, 'modelValue');
        const globalFlag = ref(true);
        const oldValue = ref();
        const settings = {
            date: {
                format: 'dd.MM.yyyy',
                placeholder: '00.00.0000',
                width: '102px',
            },
            time: {
                format: 'HH:mm',
                placeholder: '00:00',
                width: '70px',
            },
            datetime: {
                format: 'dd.MM.yyyy, HH:mm',
                placeholder: '00.00.0000, 00:00',
                width: '145px',
            },
            dates: {
                format: 'dd.MM.yyyy',
                placeholder: '00.00.0000 - 00.00.0000',
                width: '180px',
            }
        };
        const data = reactive({
            modelValue: props.mode === DATEPICKER_MODE.date || props.mode === DATEPICKER_MODE.datetime ? new Date(props.modelValue).getTime() : props.mode === DATEPICKER_MODE.dates && props.modelValue ? props.modelValue.map((d) => new Date(d).getTime()) : props.modelValue,
            prevValue: typeof props.modelValue === 'string' ? new Date(props.modelValue).getTime() : props.modelValue,
            width: props.width ? props.width : settings[props.mode].width,
        });
        const emits = __emit;
        const hours = computed(() => {
            const arr = [];
            for (let i = 0; i < 24; i++) {
                arr.push({ name: i < 10 ? `0${i}` : String(i), id: i });
            }
            return arr;
        });
        const minutes = computed(() => {
            const arr = [];
            for (let i = 0; i < 60; i++) {
                arr.push({ name: i < 10 ? `0${i}` : String(i), id: i });
            }
            return arr;
        });
        const clear = () => {
            dp.value.clearValue();
        };
        const update = () => {
            globalFlag.value = false;
            oldValue.value = null;
            if (props.mode === DATEPICKER_MODE.time) {
                const hours = data.modelValue?.split(':')[0];
                const minutes = data.modelValue?.split(':')[1];
                emits('update:modelValue', { hours: +hours, minutes: +minutes });
            }
            else if (props.mode === DATEPICKER_MODE.dates) {
                if (data.modelValue.filter(i => i).length === 2)
                    emits('update:modelValue', data.modelValue.map((d) => new Date(d).toISOString()));
            }
            else {
                emits('update:modelValue', new Date(data.modelValue).toISOString());
            }
            setTimeout(() => {
                globalFlag.value = true;
            }, 10);
        };
        watch(modelValue, next => {
            dp.value.closeMenu();
            data.modelValue = props.mode === DATEPICKER_MODE.dates && next ? next.map((d) => new Date(d).getTime()) : props.mode === DATEPICKER_MODE.date || props.mode === DATEPICKER_MODE.datetime ? new Date(next).getTime() : next;
        }, { deep: true });
        const changeYear = (m) => {
            oldValue.value = data.modelValue;
            const helperDate = m + new Date(data.modelValue).toISOString().slice(4);
            data.modelValue = new Date(helperDate).getTime();
        };
        const blurHandler = () => {
            if (oldValue.value) {
                data.modelValue = oldValue.value;
                oldValue.value = null;
            }
        };
        const handleEnter = (evt) => {
            dp.value.closeMenu();
            if (props.mode === DATEPICKER_MODE.time) {
                data.modelValue = evt.target.value;
            }
            else if (props.mode === DATEPICKER_MODE.dates) {
                data.modelValue = evt.target.value.split(' - ').map((d) => new Date(d).getTime());
            }
            else {
                const [day, month, year] = evt.target.value.split('.');
                data.modelValue = new Date(`${month}.${day}.${year}`).getTime();
            }
            update();
        };
        return (_ctx, _cache) => {
            return (_openBlock(), _createBlock(_unref(Datepicker), {
                ref_key: "dp",
                ref: dp,
                modelValue: data.modelValue,
                "onUpdate:modelValue": [
                    _cache[0] || (_cache[0] = ($event) => ((data.modelValue) = $event)),
                    update
                ],
                class: _normalizeClass(["base-datepicker", [props.theme, props.mode]]),
                locale: "ru",
                closeOnScroll: "",
                "month-name-format": "long",
                "text-input": "",
                "text-input-options": { format: settings[props.mode].format, rangeSeparator: ' - ' },
                format: settings[props.mode].format,
                previewFormat: settings[props.mode].format,
                placeholder: settings[props.mode].placeholder,
                "model-type": props.mode === 'time' ? 'format' : 'timestamp',
                "start-time": { hours: 0, minutes: 0 },
                clearable: false,
                enableTimePicker: props.mode === 'datetime' || props.mode === 'time',
                "time-picker": props.mode === 'time',
                position: "left",
                range: props.mode === 'dates',
                disabled: props.disabled,
                onBlur: blurHandler
            }, _createSlots({
                "dp-input": _withCtx(({ value, onInput }) => [
                    _createVNode(_unref(BaseInput), {
                        modelValue: value,
                        onInput: ($event) => (globalFlag.value && oldValue.value ? onInput($event) : null),
                        onKeydown: _withKeys(handleEnter, ["enter"]),
                        placeholder: settings[props.mode].placeholder,
                        mode: props.mode,
                        theme: props.theme,
                        disabled: props.disabled
                    }, null, 8, ["modelValue", "onInput", "placeholder", "mode", "theme", "disabled"])
                ]),
                "month-year": _withCtx(({ month, year, months, years, updateMonthYear, handleMonthYearChange }) => [
                    _createElementVNode("div", _hoisted_1, [
                        _createVNode(_unref(BaseIcon), {
                            class: "base-datepicker__chevrons-btn",
                            name: "chevron",
                            width: "16",
                            height: "17",
                            onClick: ($event) => (handleMonthYearChange(false))
                        }, null, 8, ["onClick"]),
                        _createElementVNode("div", _hoisted_2, [
                            _createVNode(_unref(BaseSelect), {
                                class: "base-datepicker__month",
                                modelValue: month,
                                "onUpdate:modelValue": (v) => updateMonthYear(v, year),
                                options: months.map(i => { i.name = i.text; i.id = i.value; return i; }),
                                width: "80px",
                                mode: _unref(SELECT_MODE).scroll,
                                theme: props.theme
                            }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "mode", "theme"]),
                            _createVNode(_unref(BaseSelect), {
                                class: "base-datepicker__year",
                                modelValue: year,
                                "onUpdate:modelValue": (v) => { updateMonthYear(month, v); changeYear(v); },
                                options: years.map(i => { i.name = i.text; i.id = i.value; return i; }),
                                width: "80px",
                                mode: _unref(SELECT_MODE).scroll,
                                theme: props.theme
                            }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "mode", "theme"])
                        ]),
                        _createVNode(_unref(BaseIcon), {
                            class: "base-datepicker__chevrons-btn",
                            name: "chevron",
                            width: "16",
                            height: "17",
                            onClick: ($event) => (handleMonthYearChange(true))
                        }, null, 8, ["onClick"])
                    ])
                ]),
                "action-row": _withCtx(({ selectDate }) => [
                    _createElementVNode("div", _hoisted_4, [
                        _createVNode(_unref(BaseIcon), {
                            class: "base-datepicker__actions-btn",
                            name: "delete",
                            hover: "",
                            theme: props.theme,
                            onClick: clear
                        }, null, 8, ["theme"]),
                        _createVNode(_unref(BaseIcon), {
                            class: "base-datepicker__actions-btn base-datepicker__actions-btn--save",
                            name: "save",
                            hover: "",
                            theme: props.theme,
                            onClick: selectDate
                        }, null, 8, ["theme", "onClick"])
                    ])
                ]),
                _: 2
            }, [
                (props.mode === 'datetime' || props.mode === 'time')
                    ? {
                        name: "time-picker",
                        fn: _withCtx(({ time, updateTime }) => [
                            _createElementVNode("div", _hoisted_3, [
                                _createVNode(_unref(BaseSelect), {
                                    class: "base-datepicker__time-pick",
                                    modelValue: time.hours,
                                    "onUpdate:modelValue": (v) => updateTime(+v),
                                    options: hours.value,
                                    width: "80px",
                                    mode: _unref(SELECT_MODE).scroll,
                                    theme: props.theme
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "mode", "theme"]),
                                _createVNode(_unref(BaseSelect), {
                                    class: "base-datepicker__time-pick",
                                    modelValue: time.minutes,
                                    "onUpdate:modelValue": (v) => updateTime(+v, false),
                                    options: minutes.value,
                                    width: "80px",
                                    mode: _unref(SELECT_MODE).scroll,
                                    theme: props.theme
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "mode", "theme"])
                            ])
                        ]),
                        key: "0"
                    }
                    : undefined
            ]), 1032, ["modelValue", "class", "text-input-options", "format", "previewFormat", "placeholder", "model-type", "enableTimePicker", "time-picker", "range", "disabled"]));
        };
    }
});
