import { reactive } from 'vue';
export var ModalsName;
(function (ModalsName) {
    ModalsName["Recall"] = "recall";
    ModalsName["Sms"] = "sms";
    ModalsName["Letter"] = "letter";
    ModalsName["Task"] = "task";
    ModalsName["Meeting"] = "meeting";
    ModalsName["Case"] = "case";
    ModalsName["Objection"] = "objection";
    ModalsName["Goods"] = "goods";
    ModalsName["Status"] = "status";
    ModalsName["Convert"] = "convert";
    ModalsName["KnowledgeBase"] = "knowledgeBase";
})(ModalsName || (ModalsName = {}));
export function useModalsClient() {
    const modals = reactive({
        recall: false,
        sms: false,
        letter: false,
        task: false,
        meeting: false,
        case: false,
        objection: false,
        goods: false,
        status: false,
        convert: false,
        knowledgeBase: false,
    });
    const toggleModal = (name) => {
        modals[name] = !modals[name];
    };
    return {
        modals,
        toggleModal
    };
}
